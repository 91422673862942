<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="ipamCreate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="IP Network">
                <validation-provider
                  #default="{ errors }"
                  name="IP Network"
                  vid="address"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="IP Network"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Prefix"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prefix"
                  vid="prefix"
                  rules="required"
                >
                  <v-select
                    v-model="form.prefix"
                    :options="prefixesList"
                    :searchable="false"
                    :reduce="prefix => prefix.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Allow usage of network and broadcast IP's"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_hosts_in_range"
                  class="mr-0"
                  switch
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Public"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_public"
                  class="mr-0"
                  switch
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Reserved"
                label-class="label-class"
              >
                <b-form-checkbox
                  v-model="form.is_reserved"
                  class="mr-0"
                  switch
                  inline
                />
              </b-form-group>
              <b-form-group
                label="Name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="NAS"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NASs"
                  vid="NASs"
                >
                  <v-select
                    v-model="form.nas_id"
                    :options="NASs"
                    :reduce="usageType => usageType"
                    label="name"
                    :searchable="true"
                    @search="onSearchNAS"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Network Type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Network Type"
                  vid="type"
                  rules="required"
                >
                  <v-select
                    v-model="form.type"
                    :options="typesList"
                    :reduce="type => type.id"
                    label="name"
                    :searchable="false"
                    @input="getSubnets()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="form.type === 'end'"
                label="Root"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Root"
                  vid="parent_id"
                >
                  <v-select
                    v-model="form.parent_id"
                    :options="subnetList"
                    :reduce="subnet => subnet.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-class="label-class"
                label="Usage Type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usage Type"
                  vid="usage_type"
                >
                  <v-select
                    v-model="form.usage_type"
                    :options="usageList"
                    :reduce="usageType => usageType.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Comment"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  vid="comment"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Comment"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Network Category"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Network Category"
                  vid="category_id"
                >
                  <v-select
                    v-model="form.category_id"
                    :options="categoryList"
                    :reduce="category => category.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="currentRole === 'simrush'"
                label="Owner"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Owner"
                  vid="company_id"
                >
                  <v-select
                    v-model="form.company_id"
                    :options="companyList"
                    :reduce="company => company.id"
                    label="name"
                    @search="onSearchCompanies($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, max, integer,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { debounce } from '@/utils/utils'
import { mapActions, mapState } from 'vuex'
import axios from '../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      NASs: [],
      loading: false,
      currentRole: '',
      form: {
        name: null,
        address: null,
        prefix: null,
        company_id: null,
        category_id: null,
        parent_id: null,
        type: null,
        usage_type: null,
        comment: null,
        is_hosts_in_range: false,
        is_public: false,
        is_reserved: false,
        ip_version: null,
        nas_id: null,
      },
      redirectName: null,
      required,
      max,
      integer,
    }
  },
  watch: {
    'form.nas_id': function () {
      console.log('form', this.form)
    },
  },
  computed: {
    ...mapState('ipamLists', {
      subnetList: 'subnetList',
      typesList: 'typesList',
      usageList: 'usageList',
      prefixesList: 'prefixesList',
      ipVersionsList: 'ipVersionsList',
      ipsList: 'ipsList',
      companyList: 'companyList',
      categoryList: 'categoryList',
    }),
  },
  async mounted() {
    this.loading = true
    // eslint-disable-next-line prefer-destructuring
    this.currentRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    if (this.$route.meta.navActiveLink === 'ipam-list-ipv4') {
      this.form.ip_version = 'ipv4'
      this.redirectName = 'ipv4'
      const [nasList] = await Promise.all([
        axios.get('/network/nas/index'),
        this.getTypes(),
        this.getUsage(),
        this.getPrefixes('ip_version=ipv4'),
        this.getVersions(),
        this.getCategories(),
        this.getCompanies({ query: '' }),
      ]).finally(() => {
        this.loading = false
      })
      const { data } = nasList
      this.NASs = data.data
    } else if (this.$route.meta.navActiveLink === 'ipam-list-ipv6') {
      this.form.ip_version = 'ipv6'
      this.redirectName = 'ipv6'
      const [nasList] = await Promise.all([
        axios.get('/network/nas/index'),
        this.getTypes(),
        this.getUsage(),
        this.getPrefixes('ip_version=ipv6'),
        this.getVersions(),
        this.getCategories(),
        this.getCompanies({ query: '' }),
      ]).finally(() => {
        this.loading = false
      })
      const { data } = nasList
      this.NASs = data.data
    }
  },
  // Methods for create, update and delete for ipamIpv4 && ipamIpv6 are the same. So i used ipamIpv4 as create, update and delete store.
  methods: {
    ...mapActions('ipamIpv4', [
      'createItem',
    ]),
    ...mapActions('ipamLists', [
      'getList',
      'getTypes',
      'getUsage',
      'getPrefixes',
      'getVersions',
      'getIps',
      'getCompanies',
      'getCategories',
      'getNextNetworksIpList',
    ]),
    getSubnets() {
      this.form.parent_id = null
      if (this.form.type === 'end') {
        this.getList('type=root')
      }
    },
    async onSearchNAS(searchValue) {
      axios.get(`/network/nas/index?search=name:${searchValue}`).then(res => {
        const { data } = res.data
        this.NASs = data
      })
    },
    onSearchCompanies(query) {
      if (!query) {
        return
      }
      const payload = {
        query,
      }
      this.search(payload, this.getCompanies)
    },
    search: debounce((payload, fetchAction) => {
      fetchAction({ ...payload })
    }, 350),
    async validationForm() {
      const valid = await this.$refs.ipamCreate.validate()
      if (valid) {
        try {
          const fortToSend = {
            ...this.form,
            nas_id: this.form.nas_id ? this.form.nas_id.id : null,
          }
          await this.createItem(fortToSend)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: `ipam-list-${this.redirectName}` })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.ipamCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
